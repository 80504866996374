<template>
    <div class="loginBox h-screen w-screen bg-white flex flex-col">
        <div class="h-[66px]">
          <HeaderTop :lang="lang" :tabIndex="tabIndex" @tabChange="tabChange"></HeaderTop>
        </div>
        <div class="content flex flex-1">
          <div class="w-[400px] border-r flex flex-col">
            <UserMenu :userList="userList" :tabIndex="userTabIndex" :userIndex="userItemIndex" 
            @userItemChange="userItemChange" @userTabChange="userTabChange" @userReady="getThread('load')"></UserMenu>
          </div>
          <div class="flex-1 flex flex-col">
            <!-- 内容块 -->
            <div class="flex-1 overflow-auto" ref="scrollBox" :style="loading?'opacity:0':'opacity:1'">
              <Message accept="user" :list="list"></Message>
            </div>
            <!-- 输入块 -->
            <div class="border-t p-4">
              <div>
                <!-- <el-icon :size="20" color="#666666" class="mr-2 cursor-pointer"><Picture /></el-icon> -->
                <el-icon @click="wordShowChange" :size="20" color="#666666" class="cursor-pointer"><ChatDotRound /></el-icon>
              </div>
              <div>
                <el-input
                  input-style="border:none;box-shadow:none"
                  v-model="message"
                  :autosize="{ minRows: 4, maxRows: 4 }"
                  type="textarea"
                  :disabled="!userConfig.user_id"
                  :placeholder="lang.text7"
                />
              </div>
              <div class="text-right mt-2">
                <el-button :disabled="!userConfig.user_id" @click="onSubmit" type="primary">{{ lang.submitB }}</el-button>
              </div>
            </div>

          </div>
          <div class="w-[300px] border-l overflow-auto">
            <UserBox :userInfo="userConfig" :goods_list="goods" :tabIndex="tabIndex" @postPro="postGoods"></UserBox>
          </div>
        </div>


      <el-dialog v-model="wordShow" width="760px" :title="lang.text1">
        <div class="flex">
          <!-- <div class="w-[120px] border-r border-[#eeeeee] py-2">
            <div class="flex justify-between items-center h-[36px] px-2">
              <span>分组</span>
              <el-icon @click="wordTabShow = true" :size="14" color="#999" class="cursor-pointer"><Plus /></el-icon>
            </div>
            <div class="flex justify-between items-center h-[36px] px-2 bg-sky-100">
              <span>默认</span>
            </div>
            <div class="flex justify-between items-center h-[36px] px-2">
              <span>标题名</span>
              <el-icon :size="14" color="#999" class="cursor-pointer"><More /></el-icon>
            </div>
            <div class="flex justify-between items-center h-[36px] px-2">
              <span>标题名</span>
              <el-icon :size="14" color="#999" class="cursor-pointer"><More /></el-icon>
            </div>
          </div> -->
          <div class="flex-1">
            <div class="text-right">
              <el-button @click="wordAdd()" type="primary">{{ lang.add }}</el-button>
            </div>
            <div class="list pl-4 mt-4 flex flex-wrap max-h-[50vh] min-h-[200px] overflow-y-auto">
              <div v-for="item in wordS" class="w-[220px] h-[70px] mr-2 p-2 border border-[#eeeeee] flex justify-between items-center rounded-lg mb-2">
                <div class="flex-1 cursor-pointer" @click="selectText(item)">
                  <div class="text-[14px]">{{ item.title }}</div>
                  <div class="text-[12px] text-[#999999]">{{ item.name }}</div>
                </div>
                <el-dropdown trigger="click" class="h-full flex items-center" @command="onCommand(item,$event)">
                  <span class="el-dropdown-link px-4 h-full flex items-center">
                    <el-icon :size="14" color="#999" class="cursor-pointer"><More /></el-icon>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item command="edit" icon="Edit">{{ lang.edit }}</el-dropdown-item>
                      <el-dropdown-item command="del" icon="Delete">{{ lang.del }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
              <div v-if="!wordS.length" class="text-center w-full text-[#999] py-10">
                {{ lang.text2 }}
              </div>
            </div>
          </div>
        </div>
        
        <!-- <el-dialog v-model="wordTabShow" width="500px" append-to-body title="添加分组">
          <el-form :model="wordForm" :rules="rules" ref="ref" :label-width="80">
            <el-form-item label="分组名称" prop="title">
              <el-input v-model="wordForm.title" autocomplete="off" />
            </el-form-item>
            <el-form-item label="分组排序" prop="name">
              <el-input type="number" :min="0" v-model="wordForm.title" autocomplete="off" />
            </el-form-item>
          </el-form>
          <div class="text-right">
              <el-button @click="wordAddSubmit" type="primary">确认</el-button>
          </div>
        </el-dialog> -->

        <el-dialog v-model="wordAddShow" width="500px" append-to-body :title="lang.text3">
          <el-form :model="wordForm" :rules="rules" ref="ref">
            <el-form-item :label="lang.text4" :label-width="60" prop="title">
              <el-input v-model="wordForm.title" autocomplete="off" />
            </el-form-item>
            <el-form-item :label="lang.text5" :label-width="60" prop="name">
              <el-input type="textarea" :rows="4" v-model="wordForm.name" autocomplete="off" />
            </el-form-item>
          </el-form>
          <div class="text-right">
              <el-button @click="wordAddSubmit" type="primary">{{ lang.submitA }}</el-button>
          </div>
        </el-dialog>

      </el-dialog>
    </div>
</template>
  
<script>
  import userIcon from "@/assets/user.png";
  import HeaderTop from "@/components/headerTop";
  import Message from "@/components/message";
  import UserMenu from "@/components/userMenu";
  import UserBox from "@/components/userBox";
  import { thread , message ,wordList,wordAdd,wordEdit,wordDel} from '@/utils/api'
  import {timeChange} from "@/utils/utils";
import { mapState } from 'vuex'
    export default {
    components: {HeaderTop,Message,UserMenu,UserBox},
    data:function() {
        return {
          userIcon:userIcon,
          tabIndex:0,//右上角tabs
          message:'',

          // type:'message' 消息类型||其他
          // content_type:'goods', 文本类型
          list:[],
          userList:[],

          userTabIndex:0,//聊天类型
          userItemIndex:null,//用户下标
          userConfig:{},//所选用户

          userInfo:null,//客服信息

          socketIo:'',
          socketEmitter:null,

          loading:false,
          timeBtn:null,

          wordShow:false,
          wordAddShow:false,
          wordTabShow:false,

          wordS:[],
          wordForm:{
            title:'',
            name:''
          },
          rules: {},
        };
    },
    computed:{
			...mapState(['language']),
			...mapState(['lang']),
		},
    created() {
      this.setRules()
    },
    
    mounted() {
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))||null
      if(!userInfo){
        this.$router.push({
					path:'/login'
				})
        return
      }
      this.userInfo = userInfo
      this.getThread("load")
      this.$socket.query.token = this.userInfo.socket_id
      this.$socket.connect()
      this.getWordList()
    },
    sockets:{
      connect() {
        console.log('链接成功');
      },
      disconnect() {
        console.log('断开链接')
      },
      connect_faild(){
        console.log('失败')
      },
      error(){
        console.log('错误')
      },
      receive(data){
        if(this.userItemIndex == null){
          this.getThread("load")
        }else{

          this.getThread()

          this.postMessage(data)

        }
        
      },
      postClient:data=>{
      }
    },
    methods:{
      setRules(){
        this.rules = {
            title: [{ required: true, message: this.lang.text6, trigger:['blur','change'] }],
            name: [{ required: true, message: this.lang.text7, trigger:['blur','change'] }],
          }
      },
      wordAdd(){
        this.wordAddShow = true
        this.reset()
      },
      reset(){
        this.wordForm = this.$options.data().wordForm;
        if (this.$refs["ref"] !== undefined) {
          this.$refs["ref"].resetFields();
        }
      },
      selectText(info){
        this.message = info.name
        this.wordShow = false
      },
      async onCommand(info,val){
        if(val == 'edit'){
          this.reset()
          this.wordAddShow = true
          this.wordForm = JSON.parse(JSON.stringify(info))
        }
        if(val == 'del'){
          let obj = {
            id:info.id,
          }
          
          this.$confirm(
            this.lang.text8,
            this.lang.text9,
            {
              confirmButtonText: this.lang.submitC,
              cancelButtonText: this.lang.closeA,
              type: 'warning',
            }
          ).then(async () => {
            let res = await wordDel(obj)
            this.$message.success(res.message)
            this.getWordList()

          })
        }
      },
      wordShowChange(){
        // 快捷语弹框
        this.wordShow = !this.wordShow
        this.getWordList()
      },
      async getWordList(){
        // 获取快捷语列表
        let id = this.userInfo.id
        let res = await wordList({id})
        this.wordS = res.data
      },
      
      scrollBottom(){
        // 滑动底部
        var scrollBox = this.$refs.scrollBox
        var top = scrollBox.scrollHeight - scrollBox.clientHeight
        scrollBox.scrollTop = top>0?top:0

      },
      postMessage(data){
        // 接受消息
        let arr = this.list
        let obj = data
        let user_id = this.userConfig.user_id

        obj['user_url'] = userIcon
        if(user_id == obj.user_id*1){
          arr.push(obj)
          this.list = arr
        }
        
        setTimeout(()=>{
          this.scrollBottom()
        },100)
      },
      async getMessage(type){
        // 获取历史记录
        this.loading = true

        let id = this.userConfig.id

        let res = await message({id})
        let list = res.data
        list.map(item=>{

          item['date']=timeChange(item.create_time)
          item['date_time']=(new Date(item.create_time).getTime())/1000

        })

        this.list = list
        if(type == 'load'){
          try{
            setTimeout(()=>{
              this.scrollBottom()
              this.loading = false
            },50)
          }catch(err){
            this.loading = false
          }
        }else{
          this.loading = false
        }
      },

      userTabChange(e){
        // 切换用户类型
        this.userTabIndex = e
      },

      userItemChange(e){
        // 选择用户
        this.userItemIndex = e
        let id = this.userList[e].id
        this.userConfig = this.userList[e]
        this.list = []
        this.getMessage('load')
        // this.getOrderList()
      },

      async getThread(type){
        // 获取会话列表
        let res = await thread({id:this.userInfo.id})
        this.userList = res.data

        if(this.userConfig&&this.userConfig.id){

          this.userList.map((item,index)=>{
            if(item.id == this.userConfig.id) this.userItemIndex=index
          })

        }else if(this.userList.length){

          this.userItemIndex=0
          this.userConfig = this.userList[0]
          this.getMessage(type)
          // this.getOrderList()

        }else{

          this.userItemIndex = null

        }

      },
      async getGoodsList(){
        // 获取产品列表
        let res = await goodsList()
        this.goods = res.data
      },
      tabChange(e){
        this.tabIndex = e
      },
      postGoods(info){
        // 发送产品
        clearTimeout(this.timeBtn)
        let send = this.userConfig.name
        let user_id = this.userConfig.user_id
        let obj = {
          type:'goods',
          send:send,
          goods_id:info.id,
          user_id:user_id
        }
        this.$socket.emit('chat',obj)
        this.timeBtn = setTimeout(()=>{
          this.getMessage('load')
        },200)
      },

      onSubmit(){
        // 发送消息
        let { message } = this
        let send = this.userConfig.name
        let user_id = this.userConfig.user_id
        if(!message.length) return
        let obj = {
            type:'message',
            send:send,
            user_id:user_id,
            content:message,
        }

        this.list.push(obj)
        this.$socket.emit('chat',obj)
        this.message = ''
        setTimeout(()=>{
          this.scrollBottom()
        },100)
      },

      wordAddSubmit(){
        // 添加快捷语
        this.$refs['ref'].validate(async valid => {
          if (valid) {
            let model = JSON.parse(JSON.stringify(this.wordForm))
            model['service_id'] = this.userInfo.id
            model['c_id'] = 0
            let res;
            if(model.id){
              res = await wordEdit(model)
            }else{
              res = await wordAdd(model)
            }
            this.$message.success(res.message)
            this.wordAddShow =false
            this.getWordList()
          }else{
          }
        })
      },
    }
  }
</script>
<style scoped lang="scss">
:deep(.el-textarea__inner){
  resize: none;
}
.content{
  height:calc(100% - 66px);
  
}
.textOver{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::-webkit-scrollbar{
    width:5px;
}
::-webkit-scrollbar-track{
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb{
  background-color: #aaa;
}
</style>
  