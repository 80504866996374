import request from '@/utils/request'
/**
 * @param {array} params 请求参数
 */
export function register(data) {
    return request({
      url: '/get_user/register',
      method: 'post',
      data
    })
}
export function login(data) {
  return request({
    url: '/get_user/login',
    method: 'post',
    data
  })
}

export function user_auth(data) {
  return request({
    url: '/get_user/info',
    method: 'get',
    params:data
  })
}

export function thread(data) {
  return request({
    url: '/thread/service_list',
    method: 'get',
    params:data
  })
}
export function editNote(data) {
  return request({
    url: '/thread/editNote',
    method: 'post',
    data
  })
}


export function message(data) {
  return request({
    url: '/message/list',
    method: 'get',
    params:data
  })
}

export function goodsList(data) {
  return request({
    url: '/product/list',
    method: 'get',
    params:data
  })
}

export function wordList(data) {
  return request({
    url: '/words/list',
    method: 'get',
    params:data
  })
}

export function wordAdd(data) {
  return request({
    url: '/words/add',
    method: 'post',
    data
  })
}

export function wordEdit(data) {
  return request({
    url: '/words/edit',
    method: 'post',
    data
  })
}

export function wordDel(data) {
  return request({
    url: '/words/del',
    method: 'post',
    data
  })
}