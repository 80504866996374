const languageObj = {
    cn:{
        login_title:'专业客服系统',
        login_info:'助力企业打造一流的服务体验',
        login_name:'客服登录',
        login_submit:'立即登录',
        login_account:'请输入登录名',
        login_pwd:'请输入密码',
        user_tab:'用户列表',
        user_note_info:'请输入备注',
        user_note_error:'字符长度超出限制',
        submitA:'提交',
        submitB:'发送',
        submitC:'确认',
        closeA:'取消',
        list_null:'暂无',
        online:'在线',
        phone:'手机号',
        address:'地址',
        user_info:'客户信息',
        add:'添加',
        edit:'编辑',
        del:'删除',
        text1:'快捷语录',
        text2:'暂无快捷语录',
        text3:'添加快捷语录',
        text4:'标题',
        text5:'内容',
        text6:'请输入标题',
        text7:'请输入内容',
        text8:'是否确认删除?',
        text9:'提示',
        text10:'退出登录',
        text11:'是否退出登录?',
        text12:'暂无',
        text13:'未知'
    },
    cht:{
        login_title:'專業客服系統',
        login_info:'助力企業打造一流的服務體驗',
        login_name:'客服登入',
        login_submit:'立即登入',
        login_account:'請輸入登錄名',
        login_pwd:'請輸入密碼',
        user_tab:'用戶列表',
        user_note_info:'請輸入備註',
        user_note_error:'字元長度超出限制',
        submitA:'提交',
        submitB:'發送',
        submitC:'確認',
        closeA:'取消',
        list_null:'暫無',
        online:'線上',
        phone:'手機號',
        address:'地址',
        user_info:'客戶資訊',
        add:'添加',
        edit:'編輯',
        del:'删除',
        text1:'快捷語錄',
        text2:'暫無快捷語錄',
        text3:'添加快捷語錄',
        text4:'標題',
        text5:'內容',
        text6:'請輸入標題',
        text7:'請輸入內容',
        text8:'是否確認删除？',
        text9:'提示',
        text10:'登出',
        text11:'是否登出？',
        text12:'暫無',
        text13:'未知'
    },
    en:{
        login_title:'Customer service system',
    }
}
export default languageObj