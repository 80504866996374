<template>
    <div class="flex justify-between items-center bg-sky-500 px-4 h-full">
        <div class="left flex items-center">
            <!-- <el-input placeholder="请输入内容"
                class="rounded-full overflow-hidden"
                v-model="query.search">
				<template #append>
					<el-button type="text" icon="Search" />
				</template>
            </el-input> -->
        </div>
        <div class="right flex items-center text-white h-full">
			<el-button round @click="outLogin">{{ lang.text10 }}</el-button>
			<!-- <el-dropdown trigger="click" class="h-full flex items-center" @command="onCommand($event)">
                  <span class="el-dropdown-link px-4 h-full flex items-center">
					<el-button round>
						<span v-if="language == 'cn'">中文</span>
						<span v-if="language == 'en'">English</span>
					</el-button>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item command="cn">中文</el-dropdown-item>
                      <el-dropdown-item command="en">English</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown> -->
			<div class="text-sm flex items-center h-full">
				<div @click="onTab(index)" class="ml-6 cursor-pointer h-full flex items-center" :class="tabIndex==index?'font-bold border-b-2':''" v-for="(item,index) in tabS">{{ item }}</div>
			</div>
        </div>
    </div>
</template>
<script>
	export default {
		props: {
			tabIndex:{
				type:Number||String,
				default:0
			},
			lang:Object
		},
		data() {
			return {
				query:{
					search:'',
				},
				tabS:[],
			}
		},
		watch:{
			lang(){
				this.tabS = [this.lang.user_info]
			}
		},
		created() {
			this.tabS = [this.lang.user_info]
		},
		mounted() {
		},
		methods: {
			onCommand(val){
				localStorage.setItem('language',val)
				this.$store.commit('switchLanguage',val)
			},
			outLogin(){
				this.$confirm(
					this.lang.text11,
					this.lang.text9,
					{
					confirmButtonText: this.lang.submitC,
					cancelButtonText: this.lang.close,
					type: 'warning',
					}
				).then(() => {
					sessionStorage.removeItem('userInfo')
					this.$router.push({
						path:'/login'
					})
				})
				
			},
			onTab(e){
				this.$emit('tabChange',e)
			}
		},
	}
</script>

<style scoped lang="scss">

</style>
