import { createStore } from 'vuex'
import langObj from '@/utils/lang'
// localStorage.getItem('language')||
let langType = 'cht'
let lang = langObj[langType]
export default createStore({
  state: {
    language:langType,
    lang:lang
  },
  getters: {
  },
  mutations: {
    // 切换语言类型
    switchLanguage(state, language) {
      state.language = language
      state.lang = langObj[language]
      localStorage.setItem('language', language)
    },
  },
  actions: {
  },
  modules: {
  }
})
