<template>
    <div class="w-full h-full flex flex-col">
        <div v-if="tabIndex == 0" class="w-full h-full">
            <div class="flex items-center px-2 py-4">
                <img class="rounded-full overflow-hidden w-[40px] ml-4" :src="userInfo.avatar||userIcon" alt="">
                <span class="ml-4">{{ userInfo.username||lang.text13  }}</span>
                <!-- <el-tag class="ml-2">小程序</el-tag> -->
            </div>
            <div class="border-t p-2 text-sm">
                <div class="flex my-2">
                    <div class="w-[80px] text-neutral-500">{{ lang.phone }}</div>
                    <div class="flex-1">{{ userInfo.cell_phone||lang.text12  }}</div>
                </div>
                <div class="flex my-2">
                    <div class="w-[80px] text-neutral-500">{{ lang.address }}</div>
                    <div class="flex-1">{{ userInfo.address||lang.text12 }}</div>
                </div>
                <!-- <div class="flex my-2">
                    <div class="w-[80px] text-neutral-500">推荐人</div>
                    <div class="flex-1">暂无</div>
                </div> -->
                <!-- <div class="flex my-2">
                    <div class="w-[80px] text-neutral-500">用户类型</div>
                    <div class="flex-1">暂无</div>
                </div>
                <div class="flex my-2">
                    <div class="w-[80px] text-neutral-500">余额</div>
                    <div class="flex-1">0</div>
                </div>
                <div class="flex my-2">
                    <div class="w-[80px] text-neutral-500">推广员</div>
                    <div class="flex-1">是  </div>
                </div> -->
            </div>

        </div>
        
        <div v-if="tabIndex == 1" class="w-full h-full p-4">
            <div class="px-2 py-2 rounded-sm mt-2 text-sm border-b border-slate-100" v-for="item in goods_list">
                <div class="goods flex items-center">
                    <div class="w-[64px] h-[64px] min-w-[64px] min-h-[64px]">
                      <el-image class="w-full h-full" :src="item.cover" fit="cover" />
                    </div>
                    <div class="cont ml-2 w-[200px] text-left">
                        <div class="name textOver text-sm">{{ item.name }}</div>
                        <div class="info text-xs text-neutral-400 mt-1">
                          {{ item.description }}
                        </div>
                        <div class="mt-1 flex justify-between items-center">
                            <span class="text-sm text-red-500">￥{{ item.price }}</span>
                            <el-button @click="postGoods(item)" type="text" class="text-[12px]">推送</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import userIcon from "@/assets/user.png";
    import { mapState } from 'vuex'
	export default {
		props: {
            userInfo:{
                type:Object
            },
			tabIndex:{
				type:Number||String,
				default:0
			},
            goods_list:{
                type:Array
            }
		},
		data() {
			return {
                userIcon,
			}
		},
        computed:{
			...mapState(['language']),
			...mapState(['lang']),
		},
		created() {
		},
		mounted() {
		},
		methods: {
            postGoods(info){
                this.$emit('postPro',info)
            },
		},
	}
</script>

<style scoped lang="scss">

</style>
