import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "tailwindcss/tailwind.css"

import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'

import VueSocketIO from 'vue-3-socket.io';
import sockIO from "socket.io-client";

const app = createApp(App)

const baseSocket = process.env.VUE_APP_BASE_WSS
app.config.globalProperties.socketApi = baseSocket
const socketio = new VueSocketIO({
  connection:sockIO(baseSocket,{
    transports:['websocket'],
    query:{
      token:'',
      role:'assistant',
    },
    autoConnect:false,
  }),
  autoConnect:false,
  forceJSONP:true,
  extraHeaders:{"Access-Control-Allow-Origin":'*'}
})


app.use(store)
.use(ElementPlus, {zIndex: 3000 })
.use(router)
.use(socketio)
.mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}