<template>
    <div class="w-full h-full border-r flex flex-col">
        <!-- 用户类型 -->
        <div class="h-[48px] flex items-center text-[14px]">
            <div v-for="(item,index) in contact_tabs" 
            @click="userTabChange(index)"
            class="h-full ml-[80px] flex items-center cursor-pointer hover:text-blue-600"
            :class="tabIndex==index?
            'text-blue-600 border-b border-blue-600':''">{{ item }}</div>
        </div>

        <!-- 用户列表 -->
        <div class="border-t flex-1  overflow-auto">

            <div class="flex items-center p-4 cursor-pointer hover:bg-slate-100" v-for="(item,index) in userList" 
            :class="index>0?'border-t':'',userIndex==index?'bg-slate-100':''" @click="userItemChange(index)">

                <img class="rounded-full overflow-hidden w-[40px] h-[40px] min-w-[40px]" :src="userIcon" alt="">
                
                <div class="flex-1 ml-2">
                    <div class="flex justify-between">
                        <div class="flex justify-between">
                            <div class="flex items-center">
                                <div v-if="item.note" class="max-w-[180px] overflow">
                                    {{ item.note }}
                                    <span class="text-[#999] text-[12px]">
                                        {{'('+item.username+')' }}
                                    </span>
                                </div>
                                <div v-else class="max-w-[180px] overflow">
                                    {{ item.username }}
                                </div>
                                <el-icon @click="openEdit" :size="16" color="#666666" class="ml-2 cursor-pointer"><EditPen /></el-icon>
                            </div>
                            <!-- <el-tag class="ml-2">未知</el-tag> -->
                        </div>
                    <div class="text-[14px] text-[#999]">{{ item.update_time }}</div>
                    </div>

                    <div class="textOver mt-2 text-[12px] text-[#999]">{{ item.chat_inputs }}</div>
                </div>
            </div>
            <div v-if="!userList.length" class="text-center text-sm text-[#999] p-4">暂无</div>
        </div>

        <!-- 身份信息 -->
        <div class="border-t flex items-center py-4" v-if="userInfo">

            <img class="rounded-full overflow-hidden w-[40px] ml-4" :src="userIcon" alt="">
            <span class="ml-2">{{ userInfo.nickname }}</span>
            <el-tag
            type="success"
            class="ml-2 text-[12px] min-w-[60px]"
            style="color:#333;"
            effect="light"
            round>
            在线
            </el-tag>
        </div>
    </div>
</template>
<script>
    import { editNote } from '@/utils/api'
    import userIcon from "@/assets/user.png";
	export default {
		props: {
			tabIndex:{
				type:Number||String,
				default:0
			},
            userIndex:{
                type:Number||String,
                default:0
            },
            userList:{
                type:Array,
            }
		},
		data() {
			return {
                userIcon,
                contact_tabs:['用户列表'],
                userInfo:{}
			}
		},
		created() {
            let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))||null
            this.userInfo = userInfo
		},
		mounted() {
		},
		methods: {
            async postNote(obj){
                let res = await editNote(obj)
                this.$message.success(res.message)
                this.$emit('userReady')
            },
            userItemChange(e){
                if(this.userIndex == e) return
				this.$emit('userItemChange',e)
            },
			userTabChange(e){
				this.$emit('userTabChange',e)
			},
            openEdit(){
                this.$prompt('请输入备注','',{
                    draggable: true,
                    confirmButtonText: '提交',
                    cancelButtonText: '取消',
                    inputPattern:/^.{0,30}$/,
                    inputErrorMessage: '字符长度超出限制',
                }).then(({ value }) => {
                    let obj ={
                        user_id:this.userList[this.userIndex].user_id,
                        note:value||''
                    }
                    this.postNote(obj)
                    })
                    .catch(() => {
                    })
            }
		},
	}
</script>

<style scoped lang="scss">
.overflow{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
